/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Bellefair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@1,600&family=Bellefair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Josefin+Sans:wght@100&display=swap');

/* CSS Min Reset */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.sketch-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

/* Window */
.window {
  width: 100%;
  height: 100%;
  position: fixed;
  box-shadow: inset 0 0 1000px rgba(0, 0, 0, .5);
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

/* navbar */
.navbar-container {
  position: fixed;
  width: 8%;
  height: 70%;
  min-height: 550px;
  top: 0;
  right: 0;
  padding: 3vh 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
}

.nav-icons {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 0;
  font-size: clamp(1.2rem, 1.1vw, 2.7rem);
}

.n-icons {
  margin: 3px;
  cursor: pointer;
  transition: 0.3s;
  color: rgba(255, 255, 255, .5);
}

.n-icons:hover {
  color: #ffffff;
  transform: scale(1.2);
}

.nav-line {
  border: none;
  height: 25%;
  width: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, .5);
}

@media (max-width: 1000px) {
  .navbar-container {
    display: none;
  }
}

/* contact */
.contact-container {
  position: fixed;
  width: 8%;
  height: 80%;
  min-height: 550px;
  left: 0;
  bottom: 0;
  padding: 3vh 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-icons {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(1.4rem, 1.1vw, 3rem);
}

.c-icons {
  margin: 3px;
  cursor: pointer;
  transition: 0.3s;
  color: rgba(255, 255, 255, .5);
}

.c-icons:hover {
  color: #ffffff;
  transform: scale(1.2);
}

.contact-line {
  border: none;
  height: 25%;
  width: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, .5);
}

@media (max-width: 1000px) {
  .contact-container {
    position: relative;
    width: 100%;
    height: 20%;
    min-height: 55px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .contact-icons {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .contact-line {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0;
}

/* Title */
.title-container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Josefin Sans', sans-serif;
  font-size: clamp(5rem, 8vw, 8rem);
  color: #ffffff;
  text-align: center;
}

.line {
  width: 50%;
  margin-bottom: 5vh;
  margin-top: 4vh;
}

.wrapper {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(2rem, 8vw, 2.7rem);
  box-sizing: content-box;
  height: 70px;
  padding: 50px 30px;
  display: flex;
  box-shadow: 0 20px 25px rgba(0, 0, 0, .2)
}

.wrapper>p {
  color: #D0D6F9;
}

.words {
  overflow: hidden;
  color: #ffffff;
}

.words>span {
  display: block;
  height: 100%;
  padding-left: 10px;
  font-style: italic;
  animation: spin-words 6s infinite ease-in-out;
}

@keyframes spin-words {
  10% {
    transform: translateY(-100%);
  }

  20% {
    transform: translateY(-100%);
  }

  30% {
    transform: translateY(-200%);
  }

  45% {
    transform: translateY(-200%);
  }

  55% {
    transform: translateY(-300%);
  }

  70% {
    transform: translateY(-300%);
  }

  80% {
    transform: translateY(-400%);
  }

  100% {
    transform: translateY(-400%);
  }
}

.skills-container {
  width: 100%;
  margin-top: 5vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: clamp(1.4rem, 2vw, 1.5rem);
  padding: 2vh 0;
}

@media (max-width: 1000px) {
  .skills-container {
    width: 80%;
  }
}

.skill {
  width: 2vw;
  min-width: 40px;
  height: auto;
  aspect-ratio: 1 /1;
  border: none;
  border-radius: 50%;
  background-color: rgba(208, 214, 249, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* About Me */
.about-me-container {
  width: 75%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

@media (max-width: 1000px) {
  .about-me-container {
    flex-direction: column;
    height: auto;
    margin-bottom: 10vh;
    margin-top: 5vh;
    padding: 3vh 0;
  }
}

.about-me-title {
  width: 100%;
  font-family: 'Josefin Sans', sans-serif;
  font-size: clamp(4rem, 8vw, 6.5rem);
  color: #ffffff;
  text-align: center;
}

.about-me-title-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .about-me-title-container {
    width: 100%;
    margin-bottom: 5vh;
  }
}

@media (max-width: 1000px) {
  .about-me-title {
    width: 100%;
    margin-bottom: 5vh;
  }
}

.line2 {
  height: 50vh;
  min-width: none;
  margin-bottom: 5vh;
}

@media (max-width: 1000px) {
  .line2 {
    height: 1px;
    width: 60%;
  }
}

.about-me {
  width: 40%;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1rem, 1.5vw, 1.3rem);
  letter-spacing: 2px;
  color: #ffffff;
  line-height: 1.3;
}

@media (max-width: 1000px) {
  .about-me {
    width: 100%;
    text-align: center;
  }
}

/* Projects */
.projects-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8vh 0;
}

.projects-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12vh;
}

.projects-title {
  font-family: 'Josefin Sans', sans-serif;
  font-size: clamp(4rem, 8vw, 6.5rem);
  color: #ffffff;
  margin-right: 2vw;
}

.line-projects {
  border: none;
  background-color: #ffffff;
  height: 1px;
  width: 10%;
}

.project-tile {
  width: 60%;
  height: 50vh;
  position: relative;
  margin-bottom: 12vh;
  display: flex;
  align-items: center;
}

@media (max-width: 1000px) {
  .project-tile {
    width: 90%;
  }
}

/* E-comm Site - right */
.project-image-EC {
  position: absolute;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/E-comm.jpg");
  background-repeat: none;
  background-size: cover;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-EC {
    width: 100%;
  }
}

/* HM Site - left */
.project-image-HM {
  position: absolute;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/HMcapture.PNG");
  background-repeat: none;
  background-size: cover;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-HM {
    width: 100%;
  }
}

/* Quiz App - right */
.project-image-QU {
  position: absolute;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/quizzcover.webp");
  background-repeat: none;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-QU {
    width: 100%;
  }
}

/* Code Wars - left */
.project-image-CW {
  position: absolute;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/codewars-logo-1.png");
  background-repeat: none;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-CW {
    width: 100%;
  }
}

/* Personal Website - right */
.project-image-PW {
  position: absolute;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/persport.jpg");
  background-repeat: none;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-PW {
    width: 100%;
  }
}

/* guestbook -left */
.project-image-GB {
  position: absolute;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/guestbook.png");
  background-repeat: none;
  background-size: cover;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-GB {
    width: 100%;
  }
}

/* 99 Problems - right */
.project-image-99P {
  position: absolute;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: #D0D6F9;
  background-image: url("local assets/99prob.PNG");
  background-repeat: none;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .project-image-99P {
    width: 100%;
  }
}

/* Universal Project Classes */
.project-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, .5);
  transition: .3s;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .project-overlay {
    width: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, .8);
  }
}

@media (min-width: 1000px) {
  .project-overlay:hover {
    backdrop-filter: blur(0);
    background-color: rgba(0, 0, 0, 0);
  }
}

/* Project Tile Right */
.project-description-container-right {
  position: absolute;
  width: 50%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .project-description-container-right {
    width: 100%;
  }
}

.featured-right {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  text-align: end;
}

.project-description-container-right>h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.7rem, 4vw, 2.2rem);
  color: #D0D6F9;
  text-align: end;
  margin-bottom: 1vh;
}

.project-description-right {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(11, 13, 23);
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.4);
}

.proj-descrip-text-right {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1rem, 1vw, 1.7rem);
  letter-spacing: 2px;
  color: #ffffff;
  line-height: 1.3;
}

/* Project Tile Left */
.project-description-container-left {
  position: absolute;
  width: 50%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .project-description-container-left {
    width: 100%;
  }
}

.featured-left {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1rem;
  color: #ffffff;
}

.project-description-container-left>h2 {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.7rem, 4vw, 2.2rem);
  color: #D0D6F9;
  margin-bottom: 1vh;
}

.project-description-left {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(11, 13, 23);
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.4);
}

.proj-descrip-text-left {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1rem, 1vw, 1.7rem);
  letter-spacing: 2px;
  color: #ffffff;
  line-height: 1.3;
  text-align: end;
}

.footer {
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.footer>img {
  width: 52px;
  height: 60px;
  background-color: #D0D6F9;
  box-shadow: 0 0 10px rgba(255, 255, 255, .7);
  border-radius: 50%;
  margin-top: 5vh;
}

.footer>p {
  margin-top: 5vh;
  margin-bottom: 3vh;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1rem, 1.1vw, 3rem);
  letter-spacing: 2px;
  text-align: left;
  color: #D0D6F9;
  cursor: pointer;
  transition: .1s;
}

.footer>p:hover {
  border-bottom: 2px solid #D0D6F9;
}

/* Loader */
.loader-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade 6000ms infinite;
  z-index: 5;
}

.wrapper-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10vh;
  animation: shrink 6000ms infinite;
}

.loader-image{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: pulse 3s infinite;
  transition: .6s;
  position: absolute;
  border: 1px solid #D0D6F9;
}

.wrapper-loader > img{
  width: 57px;
  height: 65px;
  position: absolute;
  z-index: 6;
}

@keyframes pulse {
  0% {
    
    box-shadow: 0 0 0 0 #D0D6F9;
  }

  70% {
    box-shadow: 0 0 0 40px rgba(255, 255, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.01);
  }
}

@keyframes fade {
  0% {
    background-color: rgba(0, 0, 0, 1);
  }

  80% {
    background-color: rgba(0, 0, 0, 1);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
}


/* GUEST BOOK */

.container{
  display: flex;
  justify-content: space-between;
  padding: 2%;
  color: white;
}

::-webkit-scrollbar {
  width: 0;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
}

.exit-guest-book{
  position: fixed;
  right: 0;
  top: 0;
  margin-right: 2vw;
  margin-top: 2vh;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.posts-title{
  font-size: clamp(3.5rem, 8vw, 6rem);
  letter-spacing: 2px;
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 2vh;
}

.post{
  width: 40vw;
  min-width: 20vw;
  margin: 4vh 0;
  border-bottom: 1px solid white;
}

@media (max-width: 1000px) {
  .post {
    width: 100%;
  }
}

.post > h1{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(2rem, 8vw, 2.2rem);
  margin-bottom: 1vh;
  font-style: italic;
  color: #D0D6F9;
}

.username{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 1.5vw, 1.7rem);
  margin-bottom: 1.5vh;
  text-decoration: underline;
}

.message{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  margin-bottom: 1vh;
}

/* Create Posts */

.open-create-post{
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 2vw;
  margin-bottom: 3vh;
  width: 20%;
  height: 10vh;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 1.5vw, 2rem);
  color: white;
  border: 2px solid white;
  box-shadow: inset 0 0 1000px rgba(0, 0, 0, .5);
  background-color: rgba(0, 0, 0, .1);
  backdrop-filter: blur(2px);
  cursor: pointer;
  transition: .2s;
}

.open-create-post:hover{
  color: black;
  background: white;
}

.create-post-container{
  position: fixed;
  right: 0;
  margin-right: 2vw;
  width: 40vw;
  height: 70vh;
  border: 3px solid white;
  border-radius: 10px;
  padding: 3%;
  box-shadow: rgba(236, 236, 236, 0.32) 0px 5px 15px;
}

@media (max-width: 1000px) {
  .create-post-container {
    width: 90%;
    box-shadow: inset 0 0 1000px rgba(0, 0, 0, .5);
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(8px);
  }
}

.create-exit{
  position: absolute;
  right: 0;
  top: 0;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 8vw, 2rem);
  color: rgba(236, 236, 236, 0.32);
  padding: 3%;
  cursor: pointer;
  transition: .2s;
}

.create-exit:hover{
  color: white;
}

Form{
  display: flex;
  flex-direction: column;
}

Form > label{
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(2rem, 8vw, 2.2rem);
  margin-bottom: 1vh;
  font-style: italic;
  color: #D0D6F9;
}

.post-title{
  border: none;
  background: none;
  margin-bottom: 4vh;
  border-bottom: 1px solid white;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 1.5vw, 2rem);
  padding: 10px;
}

.post-title:focus{
  outline: none;
}

.post-username{
  border: none;
  background: none;
  margin-bottom: 4vh;
  border-bottom: 1px solid white;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 1.5vw, 1.1rem);
  padding: 10px;
}

.post-username:focus{
  outline: none;
}

.post-message{
  border: none;
  background: none;
  height: 5rem;
  margin-bottom: 4vh;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 1.5vw, 1.1rem);
  padding: 10px;
}

.post-message:focus{
  outline: none;
}

.post-button{
  width: 20%;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(1.5rem, 1.5vw, 2rem);
  color: white;
  border: 2px solid white;
  background: none;
  cursor: pointer;
  transition: .2s;
}

.post-button:hover{
  color: black;
  background: white;
}

/* span{
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: clamp(0.7rem, 1.5vw, 1.1rem);
  font-style: italic;
  text-decoration: underline;
  letter-spacing: 2px;
  margin-bottom: .5vh;
} */